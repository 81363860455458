import React from 'react'

interface IProps {
	data:any;
	ob:any;
}

const RowDetails: React.FC<IProps> = ({
	data,
	ob,
}) => {
	if(!data) return null;
	const date=data.date?(new Date(data.date)):null

		/*
	const ok=data.category.attrs.data.reduce((resp:boolean,key:string) => {
		if(!(key[1]==="Sisäilman olosuhteet" || key[1]==="Ulkoilman olosuhteet")) {
			if(!data[key[1]]) resp=false
		}
		return resp
	},true)
		 */

	const category_attrs_data=((data.category || {}).attrs || {}).data || []

	return (<div>
		<div className="pointheader">
			{date && <span>{date.printdate()} {date.printtime()}</span>}
			{data.category.name}
		</div>

		{category_attrs_data.map((m:any,index:number) => {
			let dis=false
			if(m[1]==="Sisäilman olosuhteet" && !ob[m[1]]) { dis=true }
			if(m[1]==="Ulkoilman olosuhteet" && !ob[m[1]]) { dis=true }
			
			if(dis) { return null }
			else {
				return(<div className="pc" key={index}>
					<div className="pc1">{m[1]}:</div>
					<div className="pc2">{data[m[1]] || "-"}</div>
					<div className="pc3">{m[2]}</div>
				</div>)
			}
		})}


	</div>)
}

export default RowDetails;
