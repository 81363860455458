import React from 'react';

import CSS from 'csstype';


const classes=require('./NomgaLogo.module.scss');

interface IProps {
	size?:number;
}

const NomgaLogo: React.FC<IProps> = (props: IProps) => {
	
	const style:CSS.Properties={}
	const style2:CSS.Properties={}

	return (<a href="https://www.nomga.fi" className={classes.logoholder}>
		<img itemProp="logo" className={[classes.img,props.size?classes.big:""].join(" ")} style={style} src="/static/images/nomga-n.png" alt="nomga"/>{' '}

		<div className={[classes.LOGO,props.size?classes.big:""].join(" ")}>
			<div style={style}>NOMGA</div>
			<div style={style2}>OHJELMISTOT</div>
		</div>
	
	</a>);
}

export default NomgaLogo;
