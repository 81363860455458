import React from 'react';

import { Button } from 'reactstrap';
import { IUser } from "./store/chat/types";

import axios from './axios';

//const classes=require('./MenuBar.module.scss');

interface IProps {
	user:IUser,
}

const MenuBar: React.FC<IProps> = ({
	user,
}) => {
	const logout = () => {
		let cookies = document.cookie;

		for (let i = 0; i < cookies.split(";").length; ++i)
		{
		    let myCookie = cookies[i];
		    let pos = myCookie.indexOf("=");
		    let name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
		    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}

		axios.get("/logout/").then((resp)=>{
			sessionStorage.clear();
			localStorage.clear();

			window.location.reload();
		})
	}

	return (
		<div className="topmenu">
			<div>
				<img src={process.env.PUBLIC_URL+"/static/pelkkalogo.png"} alt="MP-Yhtymä Oy"/>
			</div>
			<div>Kirjautuneena: {user && user.username}</div>
			<div style={{textAlign:"right"}}>
				<Button color="link" onClick={logout}>Kirjaudu ulos <i className="fas fa-sign-out-alt"/></Button>
			</div>
	</div>)
}

export default MenuBar;
