import React,{ useState } from 'react';

import { connect } from "react-redux";
import { AppState } from "./store";

import { dbUpdate } from "./store/chat/actions";

import { INFile, ICard, ICustomer } from './store/chat/types';

import axios from './axios';

import Image from './Image';

import Chart from './Chart';

interface IProps {
	cards: ICard[];
	customers: ICustomer[],
	data: INFile[],
	dbUpdate: typeof dbUpdate;
	customer: INFile;
	project:INFile;
}

//const classes=require('./Main.module.scss');

const Main: React.FC<IProps> = ({
	cards,
	customers,
	dbUpdate,
	data,
	project,
	customer,
}) => {
	const [parent,setParent]=useState<INFile[]>([{name:"root",id:"21",mimetype:""}]);
	const [showReport, setShowReport]=useState<INFile | null>(null)

	const [loadIoLiving,setLoadIoLiving]=useState<boolean>(true);
	const [idlist, setIdlist]=useState<string[]>([]);
	const [namelist, setNamelist]=useState<{[id:string]:string}>({});

	const today=new Date()

		/*
	const loadParent = (index:number) => {
		axios.get("/api/?parent_id="+parent[index].id).then((resp)=>{
        dbUpdate({data:resp.data.items,customer:resp.data.customer,project:resp.data.project})
			})
			setParent(parent.splice(0,index+1))

	}
		 */

	const load = (ob:INFile) => {
		if(ob.mimetype==="06") {
			setShowReport(ob);
		}
		else {
			setParent([...parent,ob])
			axios.get("/api/?parent_id="+ob.id).then((resp)=>{
        dbUpdate({data:resp.data.items,customer:resp.data.customer,project:resp.data.project})
			})
		}
	}

	if(loadIoLiving) {
		setLoadIoLiving(false)

		axios.get("/api/?iol_devices=true").then((resp)=>{
			setNamelist(resp.data)
		})

		axios.get("/api/?iol=load").then((resp)=>{
			setIdlist(resp.data);
			//setIdlist(["277067360753967"]);
		})
	}
	const days=6;

	const dataFilter = (ob:INFile) => {
		return ["03","04","06"].includes(ob.mimetype)
	}

	const dataSort = (a:INFile,b:INFile) => {
		return a.id<b.id?-1:1
	}

	return (<div>
		{customer && <div>
			<div className="customername">
				{customer.name}
				<div>{project.name}</div>
			</div>

		{showReport && <Image imageCanvas={showReport.attrs} nfile={showReport} src={"/download/"+showReport.id+"/"+showReport.id+"/"} close={()=>setShowReport(null)}/>}

	{!showReport && <div className="customerfilecontainer">
		{/*parent.map((ob,index)=>(<span key={index} onClick={()=>loadParent(index)}> {ob.name}</span>))*/}

		{data && data.filter(dataFilter).sort(dataSort).map((ob)=>{
			return(<div key={ob.id} className="customerfile" onClick={()=>load(ob)}>
				{ob.name}<br/>
				{ob.attrs.points.length} pistettä
			</div>)
		})}
	</div>}
</div>}

	{idlist.map((id)=>(
		<Chart key={id} name={namelist[id] || id} start={today.addDays(-days)} end={today} id={id} days={days}/>
	))}
		</div>);
}

const mapStateToProps = (state: AppState) => ({
	cards: state.chat.cards,
	customers: state.chat.customers,
	data: state.chat.data,
	customer: state.chat.customer,
	project: state.chat.project,
});

export default connect(
  mapStateToProps,
  { dbUpdate }
)(Main);

