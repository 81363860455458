import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import { AppState } from "./store";

import axios from './axios';

import {
	XAxis, YAxis, CartesianGrid, Legend,
  ScatterChart, Scatter,
	ResponsiveContainer,
} from 'recharts';

interface IProps {
	start:Date;
	end:Date;
	id:string;
	days:number;
	name:string;
}

const Chart:React.FC<IProps> = (props) => {
	const [chartData, setChartData]=useState<any[]>([])

	const loadChart = (index:number=props.days,temp:any[]=[]) => {
		axios.get("/api/?iol="+props.id+"&start="+props.end.addDays(-index).todstr()).then((resp)=>{
				setChartData([...temp,...resp.data])
				if(index>0) {
					loadChart(index-1,[...temp,...resp.data])
				}
			})
	}

	useEffect(()=>{
		loadChart();
	},[props.id]);

	const scatterData=(chartData || []).reduce((resp:any,ob:any)=>{
		const h=ob.name-props.start.setHours(0,0,0,0)/1000
		resp.temp1.push({x:h,y:ob.temp1})
		resp.temp2.push({x:h,y:ob.temp2})
		resp.hum1.push({x:h,y:ob.hum1})
		resp.hum2.push({x:h,y:ob.hum2})

		return resp

	},{temp1:[],temp2:[],hum1:[],hum2:[]})

	const hours=Math.floor((props.end.setHours(23,59,59,999)-props.start.setHours(0,0,0,0))/1000/60/60); ////24+1;
	const myShape = () => (<span></span>)

	let prevDate:String=""
	const tickDate = (tick:any) => {
		const d=new Date(props.start.setHours(0,0,0,0)+tick*1000);
		const ret=prevDate!==d.printdate()?d.printdate():""
		prevDate=d.printdate()
		return ret
		//		return d.printdate()
	}
	const tickTime = (tick:any) => {
		const d=new Date(props.start.setHours(0,0,0,0)+tick*1000);
		return d.printtime()

	}


	return (<div style={{"textAlign":"center"}} className="chartholder">
		<h5>{props.name}</h5>
		<ResponsiveContainer width="100%" height={300}>
      <ScatterChart
        margin={{
          top: 5, right:30, bottom: 5, left: 20,
				}}
			>
			
				<CartesianGrid />
				
				<XAxis type="number" dataKey="x" name="stature" unit="" ticks={Array(hours).fill(0).map((_,i)=>i*60*60)} tickFormatter={tickTime}/>
				<XAxis  xAxisId={2} tickLine={false} axisLine={false} type="number" dataKey="x" name="stature" unit="" ticks={Array(hours).fill(0).map((_,i)=>i*60*60)} tickFormatter={tickDate}/>

				<YAxis type="number" dataKey="y" yAxisId="left" unit="°C"/>
        <YAxis type="number" dataKey="y" yAxisId="right" unit="%" orientation="right" />

				<Legend />
			
				<Scatter yAxisId="left" name="Lämpötila 1" data={scatterData.temp1} fill="#000000" line shape={myShape}/>
				<Scatter yAxisId="left" name="Lämpötila 2" data={scatterData.temp2} fill="#ff0000" line shape={myShape}/>
        <Scatter yAxisId="right" name="Kosteus 1" data={scatterData.hum1} fill="#00ff00" line shape={myShape}/>
        <Scatter yAxisId="right" name="Kosteus 2" data={scatterData.hum2} fill="#0000ff" line shape={myShape}/>
				
      </ScatterChart>
		</ResponsiveContainer>
	</div>)
}

const mapStateToProps = (state: AppState) => ({
})

export default connect(
	mapStateToProps,{}
)(Chart)


