import React from 'react';
import './App.css';

import { Link } from 'react-router-dom'

const App = () => {
	return (<div>
		<h1><Link to="/">notfound takas</Link></h1>

		</div>
  );
}

export default App;
