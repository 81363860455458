
export enum AppLanguage {
		Suomi = 'fi',
		English = 'en',
}

export enum AppRoute {
	Home = 'routes.home',
	Todo = 'routes.todo',
	Customers = 'routes.customers',
	Contacts = 'routes.contacts',

	Warehouse = 'routes.warehouse',
	Items = 'routes.items',
	Suppliers = 'routes.suppliers',
	Orders = 'routes.orders',
	Usage = 'routes.usage',
}


