import React from 'react';

import NomgaLogo from './NomgaLogo';

const Footer = () => {
  return (<div className="footer">
		<div className="footeradd">
			MEILLÄ ASIAT HOIDETAAN JÄMPTISTI JA SOVITUSTI
		</div>

<div className="container">

			<div className="row">

				<div className="col">

					<footer id="colophon" className="site-footer" role="contentinfo">

						
						
						<div className="widgets-footer">
							<div className="container">
								<div className="row">
									
    <div className="col-md-12 before-footer">
                <div className="empty-space-widget" style={{"height":25}}></div>
                        			<div className="textwidget"><div style={{"textAlign":"center","marginBottom":30}}><img className="alignnone size-medium wp-image-1001" src="https://www.mp-yhtyma.fi/wp-content/uploads/2018/07/logotekstillap-1-300x42.png" alt="" width="300" height="42"/></div>
</div>
                <div className="empty-space-widget" style={{"height":25}}></div>
    </div>

<div className="col-md-6">
		            

                        			<div className="textwidget"><p>MP-Yhtymä Oy on johtava kiinteistö- ja rakennusalan kuivaustekniikan ja teknisten mittausten palveluyhtiö.</p>
</div>
			</div>

<div className="col-md-6">
		            
                <div className="vslmd-widget-container contat-information-widget widget-align-center">
                    
									 <span><i className="fa fa-phone"/> 010 3244 320</span>{' '}
									 <span><i className="fas fa-map-marker-alt"/> Viinikankatu 51, 33800 Tampere</span>
									 <br/>
									 <span><i className="far fa-envelope"/> matti.puranen@mp-yhtyma.fi</span>
                    
                </div>
            
             	</div>


								</div>
							</div>
						</div>

						
						<div className="site-info bottom-footer">
							<div className="container">
								<div className="row align-items-center">
									<div className="col-md-6 copyright-footer-item">
																				<span>MP-Yhtymä Oy - 2018&nbsp; &nbsp;| <a href="https://www.mp-yhtyma.fi/wp-content/uploads/2018/09/MP-Yhtymä_Tietosuojaseloste.pdf">Tietosuojaseloste</a></span>
																			</div>
																	</div>
							</div>
						</div>

					</footer>

				</div>

			</div>

		</div>
		<div className="nomga"><NomgaLogo/></div>
  </div>);
}

export default Footer;
