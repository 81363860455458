import React from 'react'

import { connect } from 'react-redux'

import RowDetails from './RowDetails';

class ImageCanvas extends React.Component{
	constructor(props) {
		super(props)
		this.state={
			width:100,
			height:100,
			imgHeight:100,
			imgWidth:100,
			img:null,
			a:1,
			hover:"",
		}
		this.imgCanvas=React.createRef()
	}

	redrawCanvas = () => {
		const { img } = this.state;

		if(this.imgCanvas.current && img) {
			try {
				const context=this.imgCanvas.current.getContext("2d");

				
				const xm=1024;
				const s=xm/img.width;
			
				context.drawImage(img,0,0,img.width,img.height,0,0,1024,img.height*1024/img.width);

				(this.props.imageCanvas.points || []).forEach((ob)=> {
					if(ob.nfile_id===this.props.nfile.id) {
						const x=ob.x*s;
						const y=ob.y*s;
						context.beginPath();
						context.strokeStyle="#ff0000";
						context.lineWidth=3;
						context.fillStyle="rgba(255,0,0,0.2)";
						context.arc(x,y,20,0,2*Math.PI);
						context.fill();
						context.stroke();

						context.fillStyle="rgba(0,0,255,1)";
						context.strokeStyle="#ffffff";
						context.lineWidth=5;

						context.font = "20px Arial";

						context.strokeText(ob.name, x, y);
						context.fillText(ob.name, x, y);
					}
				})

				// virheen poisto, ei ehkä paras
				setTimeout(()=>{
					this.props.updateImages(this.props.nfile.id,this.imgCanvas.current.toDataURL());
				},10)
			} catch(err) {
				console.log("err",err)
			}

		}
	}

	componentDidMount() {
		if(this.props.nfile && this.props.nfile.upload) {
			const img=new Image()

			const fname=this.props.nfile.upload?this.props.nfile.upload.split("/").pop():"nofile"
			const ext=fname.split(".").pop()
			
			const hostname = window && window.location && window.location.hostname;

			if(hostname==="localhost") {
				img.src="http://localhost:8000/api/?img=img&p1="+this.props.nfile.id+"&p2="+fname+"&ext="+ext
		    img.setAttribute('crossorigin', 'anonymous')
			}
			else {
				img.src="/api/?img=img&p1="+this.props.nfile.id+"&p2="+fname+"&ext="+ext
			}

			const canvas=this.imgCanvas.current

			img.onload=()=> {
				const a=img.width/img.height
				const w=Math.min(810,canvas.parentNode.clientWidth)
				const h=w/a

				this.setState({
					a:w/img.width,
					imgWidth:img.width,
					imgHeight:img.height,
					width:w, //img.width, //w,
					height:h, //img.height, //h,
					img:img,
				})
				this.redrawCanvas()
			}
		}
	}

	render() {
		this.redrawCanvas();

	return (
		<div
			width="100%"
		>
			{ this.props.nfile && this.props.nfile.upload && <div>
				<canvas
					className="pointdata"
					style={{ "width":this.state.width,"height":this.state.height}}
					ref={this.imgCanvas}

		  		width={1024}
					height={this.state.imgHeight*1024/this.state.imgWidth}
				/>
				{/*((this.props.imageCanvas.points || []).filter((ob)=>ob.nfile_id==this.props.nfile.id) || []).length===0 && <div>
				</div>*/}
			</div>}

			{(this.props.imageCanvas.points || []).map((ob,index)=>{
				if(ob.nfile_id===this.props.nfile.id) {
					const obvalues=Array.isArray(ob.values)?ob.values:[]
					return(

					<div key={index} className="pointdata">
						<div className="pointtitle">
						<div className="pointname">{ob.name || "<nimetön>"}</div>
						{ob.description && <div className="pointdesc">{ob.description}</div>}
						</div>
						<div className="header">
						<div><div>Sisäilman olosuhteet:</div> {ob.weather_in} RH% / °C</div>
						</div>

						{(obvalues || []).map((p,index2)=>{
							return(<RowDetails key={index2} data={p} ob={ob}/>)
						})}

					</div>
					)
				}
				return null;
			})}

	</div>)
	}

}

const mapStateToProps = (state) => ({
	NFile:state.chat.data,
	//windowSize:state.websocket.windowSize,
	//ws:state.websocket.webSocket,
	//NFile:state.websocket.models.NFile,
	//callbacks:state.websocket.callbacks,
	//weather:state.websocket.weather,
	//imageCanvas:state.websocket.imageCanvas,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	setData:(name,value) => console.log("dispatch(setData(name,value))"),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ImageCanvas)
