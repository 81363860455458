import React, { useState } from 'react';

import { connect } from "react-redux";
import { AppState } from "./store";


import { Route, BrowserRouter as Router } from 'react-router-dom'

import { LocalizedSwitch, LocalizedRouter, appStrings } from './i18n';
import { AppLanguage, AppRoute } from './i18n/const';

import NotFound from './NotFound';

import Main from './Main';
//import Todo from './Todo';
//import Customers from './Customers';

import LoginModal from './LoginModal';

//import Item from './items/Item';
//import Supplier from './suppliers/Supplier';
//import Order from './orders/Order';
//import Warehouse from './warehouse/Warehouse';
//import Usage from './usage/Usage';
import axios from './axios';

import './App.css';

//import { loadCards } from './InitScript'
import { dbUpdate } from "./store/chat/actions";

import { ICustomer, IUser } from "./store/chat/types";

import MenuBar from './MenuBar';

import Footer from './Footer';


interface IProps {
	defaultLanguage?: AppLanguage;
	dbUpdate: typeof dbUpdate;
	customers: ICustomer[];
	loggedIn:boolean,
	user:IUser,
}


const App: React.FC<IProps> = ({
	defaultLanguage,
	dbUpdate,
	customers,
	loggedIn,
	user,
}) => {

	const [init, setInit]=useState<boolean>(false);

	if(!init && loggedIn) {
		console.log("INIT");
		setInit(true);
		//loadTasks(dbUpdate,customers);
		//loadCards(dbUpdate,customers);
		//
	
		axios.get("/login/").then((resp)=>{
			dbUpdate({
				loggedIn:true,
				user:resp.data as IUser
			})

			// load from mpyhtyhma
			axios.get("/api/?parent_id=1059").then((resp)=>{
				dbUpdate({data:resp.data.items,customer:resp.data.customer,project:resp.data.project})
			})

		}).catch(()=>{
			sessionStorage.clear();
			localStorage.clear();

			window.location.reload();
		})

	}

	return (
	<LocalizedRouter
		RouterComponent={Router}
		languages={AppLanguage}
		appStrings={appStrings}
		defaultLanguage={defaultLanguage}
	>
		<MenuBar user={user}/>
		<div className="mainz">
			<LocalizedSwitch>
				<Route exact path={AppRoute.Home} component={Main} />
				{/*<Route exact path={AppRoute.Todo} component={Todo} />
				<Route exact path={AppRoute.Customers} component={Customers} />*/}
				{/*<Route exact path={AppRoute.Contacts} component={Todo} />*/}

				{/*
				<Route exact path={AppRoute.Items} component={Item} />
				<Route exact path={AppRoute.Suppliers} component={Supplier} />
				<Route exact path={AppRoute.Orders} component={Order} />
				<Route exact path={AppRoute.Warehouse} component={Warehouse} />
				*/}

				<Route component={NotFound} />
			</LocalizedSwitch>
		</div>
		<Footer/>
		<LoginModal />

 	</LocalizedRouter>


  );
}

const mapStateToProps = (state: AppState) => ({
	cards: state.chat.cards || [],
	customers: state.chat.customers || [],
	loggedIn: state.chat.loggedIn,
	user: state.chat.user,
});

export default connect(
  mapStateToProps,
	{ dbUpdate }
)(App);

