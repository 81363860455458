import React from 'react'

import { connect } from 'react-redux'

import { Button, Container } from 'reactstrap'

import ImageCanvas2 from './ImageCanvas'

import axios from './axios';

import RowDetails from './RowDetails';

class Image2 extends React.Component{
	constructor(props) {
		super(props)
		this.state={
			loading:false,
			width:100,
			height:100,
			imgHeight:100,
			imgWidth:100,
			a:1,
			hover:"",

			images:{},
			imageList:(((props.nfile || {}).attrs || {}).images || []).map((key)=>(props.NFile[key] || key)),

			uploadOpen:false,
		}
		this.imgCanvas=React.createRef()		
	}

	createPDF = (data) => {
		this.setState({loading:true})
		const img=this.imgCanvas.current?this.imgCanvas.current.toDataURL():null
		const project=this.props.project; //{name:"KOHDE"} //null; //this.props.NFile[data.parent]
		const customer=this.props.customer; //{name:"ASIAKAS"} //null; //this.props.NFile[project.parent]

		let event={};
		try {
			event={start:(new Date(data.attrs.points[0].values[0].date)).todtstr()}
		} catch(err) {}

		const attr={...data,img:img,project:project,customer:customer,images:this.state.images,event:event,}
console.log(attr)
		axios.post("/api/",attr).then((resp)=>{
	    let blob = new Blob([atob(resp.data)], { type:"application/pdf" })

    	let link = document.createElement('a')			
			link.setAttribute('download', "report.pdf")

  	  link.href = window.URL.createObjectURL(blob)
      link.click()

			this.setState({loading:false})

		}).catch((resp)=>{
			console.log("ERROR",resp)
		})

	}

	click(e) {
		this.props.getImage(this.imgCanvas.current.getTrimmedCanvas().toDataURL())
	}
	cancel(e) {
		this.props.close()
	}

	redrawCanvas = () => {
		if(this.imgCanvas.current) {

			try {
				const context=this.imgCanvas.current.getContext("2d")
				const { img } = this.state
				
				const xm=1024
				const s=xm/img.width
				
				context.drawImage(img,0,0,img.width,img.height,0,0,1024,img.height*1024/img.width)

				this.props.imageCanvas.points.forEach((ob)=> {
					if(!ob.nfile_id) {
					const x=ob.x*s //*this.state.a
					const y=ob.y*s //*this.state.a
					context.beginPath()
					context.strokeStyle="#ff0000"
					context.lineWidth=3
					context.fillStyle="rgba(255,0,0,0.2)"
					context.arc(x,y,20,0,2*Math.PI)
					context.fill()
					context.stroke()

					context.fillStyle="rgba(0,0,255,1)"
					context.strokeStyle="#ffffff"
					context.lineWidth=5

					context.font = "20px Arial";

					context.strokeText(ob.name, x, y);
					context.fillText(ob.name, x, y);
					}
				})
			} catch(err) {}
		}
	}

	updateImages = (id,img) =>{
		this.setState({images:{...this.state.images,[id]:img}})
	}
	
	componentDidMount() {
		if(this.props.nfile.upload) {
			const img=new Image()
			const ext=this.props.nfile.name.split(".").pop()
		
			const hostname = window && window.location && window.location.hostname;

			if(hostname==="localhost") {
				img.src="http://localhost:8000/api/?img=img&p1="+this.props.nfile.id+"&p2="+this.props.nfile.id+"&ext="+ext
		    img.setAttribute('crossorigin', 'anonymous')
			} else {
				img.src="/api/?img=img&p1="+this.props.nfile.id+"&p2="+this.props.nfile.id+"&ext="+ext
			}

			const canvas=this.imgCanvas.current

			img.onload=()=> {
				const a=img.width/img.height
				const w=Math.min(810,canvas.parentNode.clientWidth)
				const h=w/a

				this.setState({
					a:w/img.width,
					imgWidth:img.width,
					imgHeight:img.height,
					width:w, //img.width, //w,
					height:h, //img.height, //h,
					img:img,
				})
				this.redrawCanvas()
			}
		}
	}

	render() {

		const attrs=((this.props.nfile || {}).attrs || {})

		const weather_out=((this.props.nfile || {}).attrs || {}).weather_out || ""

		let pvm="";
		try {
			pvm=(new Date(attrs.points[0].values[0].date)).printdate()
		} catch(err) {}


		this.redrawCanvas();
	return (<Container>
		<div
			className="reportframe"
			width="100%"
		>
			<div style={{"float":"right"}}>
				<Button onClick={this.props.close}>
					<i className="fas fa-times"/>
				</Button>
			</div>

			<div className="customer">{this.props.customer.name} / {this.props.project.name}</div>
			<div className="header">
				<div><div>Mittaaja:</div> {attrs.employee}</div>
				<div><div>Päivämäärä:</div> {pvm}</div>
				<div><div>Mittapää:</div> {attrs.device}</div>
				<div><div>Ulkoilman olosuhteet (RH% / °C):</div> {weather_out}</div>
			</div>

			{ this.props.nfile.upload &&
				<canvas 
					className="pointdata"
					style={{ "width":this.state.width,"height":this.state.height}}
					ref={this.imgCanvas}

		  		width={1024}
					height={this.state.imgHeight*1024/this.state.imgWidth}
				/>}
			
			{(this.props.imageCanvas.points || []).map((ob,index)=>{
				if(!ob.nfile_id) {
					const obvalues=Array.isArray(ob.values)?ob.values:[]

					return(
					<div key={index} className="pointdata">
						<div className="pointtitle">
						<div className="pointname">{ob.name || "<nimetön>"}</div>
						{ob.description && <div className="pointdesc">{ob.description}</div>}
						</div>
						<div className="header">
						<div><div>Sisäilman olosuhteet (RH% / °C):</div> {ob.weather_in}</div>
						</div>


						{(obvalues || []).map((p,index2)=>{
							return(<RowDetails key={index2} data={p} ob={ob}/>)
						})}

					</div>
					)
				}
				return null;
			})}
		
			{this.state.imageList.map((img,index) => {
				const nfile=this.props.NFile.find((ob)=>ob.id===img)
				return !nfile?null:(				
				<ImageCanvas2 key={index}
					updateImages={this.updateImages}
					nfile={nfile}
					imageCanvas={this.props.imageCanvas}
				/>)
			})}

			<div style={{textAlign:"right"}}>
				<Button disabled={this.state.loading} onClick={()=>this.createPDF(this.props.nfile)}>
					{this.state.loading
						?<i className="spin fas fa-sync"/>
						:<i className="fas fa-file-pdf"/>}
					{' '} Raportti
				</Button>{' '}

				<Button onClick={this.props.close}>
					<i className="fas fa-times"/> Sulje
				</Button>

			</div>

	</div>


	</Container>)
	}

}

const mapStateToProps = (state) => ({
	NFile: state.chat.data,
	customer: state.chat.customer,
	project: state.chat.project,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Image2)
