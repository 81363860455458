const baseStrings = {
	'routes.home':'/',
	'routes.todo':'/todo',
	'routes.customers':'/customers',
	'routes.contacts':'/contacts',


	'routes.warehouse':'/varastonhallinta',
	'routes.items':'/tuotteet',
	'routes.suppliers':'/toimittajat',
	'routes.orders':'/tilaukset',
	'routes.usage':'/kulutus',

	'sidebar.home':'Koti',
	'sidebar.chart':'Käppyrä',

	'sidebar.warehouse':'Varasto',
	'sidebar.items':'Tuotteet',
	'sidebar.suppliers':'Toimittajat',
	'sidebar.orders':'Tilaukset',
	'sidebar.usage':'Kulutus',

}

export type LanguageStrings = typeof baseStrings;

export const fi = baseStrings;
